






































































import { Component, Watch, Prop, Vue } from 'vue-property-decorator';

import FormField from '@/shared/resources/components/forms/FormField.vue';
import { Validation } from 'vuelidate';
import DateRangeInterface from '@/shared/lib/interfaces/DateRangeInterface';

import dateTimeConfig from '@/shared/lib/config/dateTimeConfig';
import moment from 'moment';
import Icon from '@/shared/resources/components/Icon.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import DateRangePresetInterface from '@/shared/lib/interfaces/DateRangePresetInterface';

@Component({
  components: {
    InputField,
    Card,
    Button,
    GridCol,
    GridRow,
    Icon,
    FormField,
  },
})
export default class DateRangeField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: string[];
  @Prop() private name!: string;
  @Prop() private label?: string;
  @Prop() private description?: string;
  @Prop() private validation?: Validation;
  @Prop({ default: false, type: Boolean }) private required!: boolean;
  @Prop({ default: false, type: Boolean }) private solo!: boolean;
  @Prop() private customPresets?: DateRangePresetInterface[];

  /**
   * Data
   */
  private show: boolean = false;
  private dateFrom: string = this.value[0];
  private dateTo: string = this.value[1];

  private defaultPresets: DateRangePresetInterface[] = [
    {
      title: 'Dzisiaj',
      from: moment().format(dateTimeConfig.dateFormat),
      to: null,
    },
    {
      title: 'Wczoraj',
      from: moment().subtract('1', 'day').format(dateTimeConfig.dateFormat),
      to: null,
    },
    {
      title: 'Bieżący tydzień',
      from: moment().startOf('isoWeek').format(dateTimeConfig.dateFormat),
      to: moment().format(dateTimeConfig.dateFormat),
    },
    {
      title: 'Poprzedni tydzień',
      from: moment().subtract('1', 'week').startOf('isoWeek').format(dateTimeConfig.dateFormat),
      to: moment().subtract('1', 'week').endOf('isoWeek').format(dateTimeConfig.dateFormat),
    },
    {
      title: 'Bieżący miesiąc',
      from: moment().startOf('month').format(dateTimeConfig.dateFormat),
      to: moment().format(dateTimeConfig.dateFormat),
    },
    {
      title: 'Poprzedni miesiąc',
      from: moment().subtract('1', 'month').startOf('month').format(dateTimeConfig.dateFormat),
      to: moment().subtract('1', 'month').endOf('month').format(dateTimeConfig.dateFormat),
    },
    {
      title: 'Bieżący rok',
      from: moment().startOf('year').format(dateTimeConfig.dateFormat),
      to: moment().format(dateTimeConfig.dateFormat),
    },
    {
      title: 'Poprzedni rok',
      from: moment().subtract('1', 'year').startOf('year').format(dateTimeConfig.dateFormat),
      to: moment().subtract('1', 'year').endOf('year').format(dateTimeConfig.dateFormat),
    },
  ];

  /**
   * Getters
   */
  private get datesFormatted(): string {
    return this.value.join(' - ');
  }

  private get presets(): DateRangePresetInterface[] {
    return this.customPresets || this.defaultPresets;
  }

  /**
   * Methods
   */
  private presetButtonColor(preset: DateRangePresetInterface) {
    const from: string = preset.from;
    const to: string = preset.to || preset.from;

    return this.dateFrom === from && this.dateTo === to ? 'primary' : 'default';
  }

  /**
   * Handlers
   */
  private onDateToPickerInput(dateTo: string) {
    this.show = false;
    this.$emit('change', [this.dateFrom, dateTo]);
  }

  private onClickPresetButton(preset: any) {
    const from: string = preset.from;
    const to: string = preset.to || preset.from;

    this.show = false;
    this.$emit('change', [from, to]);
  }

  /**
   * Watcher
   */
  @Watch('value')
  private watchValueChange(value: string[]) {
    this.dateFrom = value[0];
    this.dateTo = value[1];
  }
}
